import React from 'react'
import './Plans.css'
import {plans, plansData} from '../../data/plansdata'
import whitetick from '../../assets/whiteTick.png'
import RightArrow from '../../assets/rightArrow.png'

function Plans() {
    return (
        <div className="plans-container" id='Plans'>
            <div className="blur plans-blur-1"></div>
            <div className="blur plans-blur-2"></div>
            <div className="prog-header" style={{gap:'2rem'}}>
                <span className='stroke-text'>READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className='stroke-text'>WITHUS</span>
            </div>

{/* Plans Card */}
            <div className="plans">
                {plansData.map((plan, i)=> (
                    <div className="plan" key={i}>
                        {plan.icon}
                        <span>{plan.name}</span>
                        <span>$ {plan.price}</span>

                        <div className="features">
                            {plan.features.map((feature, i)=>(
                                <div className="feature">
                                    <img src={whitetick} alt="" />
                                    <span key={i}>{feature}</span>
                                </div>
                            ))}
                        </div>
                        <div>
                            <span>See more benefits <img src={RightArrow} alt="" style={{width:'1rem'}} /></span>
                        </div>
                        <button className='btn'>Join now</button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Plans

import React from 'react'
import './Program.css'
import{programsData} from '../../data/programsdata'
import RightArrow from '../../assets/rightArrow.png'
function Program() {
    return (
        <div className="prog" id="program">

{/* Header */}
            <div className="prog-header">
                <span className='stroke-text'>Explore our</span>
                <span>Programs</span>
                <span className='stroke-text'>to shape you</span>
            </div>
            <div className="program-cat">
                {programsData.map((program) => (
                    <div className="category">
                        {program.image}
                        <span>{program.heading}</span>
                        <span>{program.details}</span>
                        <div className="join-now">Join Now <img src={RightArrow} alt="" /></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Program
